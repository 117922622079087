import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import {motion, AnimatePresence} from 'framer-motion'

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const variants = {
    initial: {
      y: '-50vh',
      opacity: 0,
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        type: 'bounce'
      }
    },
    closed: {
      y: '-50vh',
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    }
  }
  return (
    <nav className="max-w-screen-2xl w-full mx-auto fixed lg:top-10 top-5 left-0 right-0 xl:px-0 lg:px-3 md:px-10 sm:px-5 px-4">

      <div className="lg:grid grid-cols-11 gap-2 items-center hidden">
        <div className="col-span-4 flex justify-start items-center">
          <div className="px-5 text-sm grow">
            <Link href={"https://www.benandbenmusic.com/"} passHref>
              <Image className="cursor-pointer" src={"/assets/bblogo.png"} alt={'Ben&Ben Logo'} width={47} height={47} />
            </Link>
          </div>
          <div className="px-5 text-sm grow">
            <NavLinks textLink={'home'} href={'/'}/>
          </div>
          <div className="px-5 text-sm grow">
            <NavLinks textLink={'about ben&ben'} href={'/about'}/>
          </div>
        </div>

        <div className="col-span-3 text-center">
          <div className="towol-logo mx-auto">
            <Link href={"/"}>
              <Image className="cursor-pointer" src={"/assets/towollogo.png"} alt={'Ben&Ben Logo'} width={245} height={110} />
            </Link>
          </div>
        </div>

        <div className="col-span-4 flex justify-end items-center">
          <div className="px-5 text-sm text-end grow">
            <NavLinks textLink={'lyrics'} href={'/lyrics'}/>
          </div>
          <div className="px-5 text-sm text-end grow">
            <NavLinks textLink={'WRITE A POSTCARD'} href={'/postcard'}/>
          </div>
        </div> 
      </div>

      <div className="grid grid-cols-3 gap-1 items-center lg:hidden z-30">
        <div className="col-span-1 flex justify-start items-center">
          <Link href={"https://www.benandbenmusic.com/"} passHref>
            <Image className="cursor-pointer" src={"/assets/bblogo.png"} alt={'Ben&Ben Logo'} width={47} height={47} />
          </Link>
        </div>

        <div className="col-span-1 text-center">
          <div className="mobile-logo mx-auto">
            <Link href={"/"}>
              <Image className="cursor-pointer" src={"/assets/towollogo.png"} alt={'Ben&Ben Logo'} width={245} height={110} />
            </Link>
          </div>
        </div>
        
        <div className="col-span-1">
          <div className="flex justify-end lg:hidden">
            <button type="button" onClick={() => {setMobileMenuOpen(!isMobileMenuOpen)}} className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none focus:ring-0" aria-controls="mobile-menu" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence>
      {isMobileMenuOpen ? (
        <motion.div
        variants={variants}
        initial="initial"
        animate="open"
        exit="closed"
        className="lg:hidden p-4 bg-black/70 z-10" id="mobile-menu">
        <div className="px-5 py-3 text-sm">
          <NavLinks textLink={'home'} href={'/'}/>
        </div>
        <div className="px-5 py-3 text-sm">
          <NavLinks textLink={'about ben&ben'} href={'/about'}/>
        </div>
        <div className="px-5 py-3 text-sm">
          <NavLinks textLink={'lyrics'} href={'/lyrics'}/>
        </div>
        <div className="px-5 py-3 text-sm">
          <NavLinks textLink={'WRITE A POSTCARD'} href={'/postcard'}/>
        </div>
      </motion.div>
      ) : null}
      </AnimatePresence>
    </nav>
  );
};

const NavLinks = ({textLink, href}) => {
  return (
    <Link href={href}>
      <p className="uppercase font-Montserrat text-white font-semibold cursor-pointer">{textLink}</p>
    </Link>
  )
}

export default Navbar;
