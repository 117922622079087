import Image from "next/image";
import Link from "next/link";
import BaseTemplate from "@client/components/BaseTemplate";
import { STREAMING_LINKS } from "@src/constant";
import { useState, useEffect } from "react";
import { KTGet } from "@server/lib/db";
import { useQuery } from "@tanstack/react-query";
import { NextSeo } from "next-seo";

const Home = () => {

  // const [PostcardData, setPostCards] = useState(0);

  // const getPostcards = async () => {

  //   let queryParams = {
  //     // orderBy: 'id,asc'
  //   };

  //   return await KTGet('public/random-post', queryParams)
  // }

  // const { isLoading, error, data: PostcardData } = useQuery(['PostcardData'], getPostcards)


  return (
    <>
      <NextSeo
        title="The Ones We Once Loved"
        description="The Ones We Once Loved is a song about closure. Words you wanted to say to your past, or wanted to hear from them but never go to."
        openGraph={{
          url: "https://www.theonesweonceloved.com/",
          title: "The Ones We Once Loved",
          description:
            "The Ones We Once Loved is a song about closure. Words you wanted to say to your past, or wanted to hear from them but never go to.",
          images: [
            {
              url: "https://ucarecdn.com/9648ae51-c5a2-463d-8cfe-777563555bef/-/preview/800x800/",
              width: 800,
              height: 600,
              alt: "Og Image Alt",
              type: "image/jpeg",
            },
          ],
          site_name: "The Ones We Once Loved",
        }}
        twitter={{
          handle: "@BenAndBenMusic",
          site: "@BenAndBenMusic",
          cardType: "summary_large_image",
        }}
        additionalLinkTags={[

          {
            rel: 'icon',
            href: '/assets/bblogo.png',
          }
        ]}
      />
      <BaseTemplate bgClass={"home-bg bg-fixed bg-top max-w-none !pl-0 !pr-0"} childClass={"space-y-4 max-w-none mx-auto"}>
        <div className="d-block mt-20"></div>
        <section className="md:px-10 px-6">
          <iframe
            className="mx-auto xl:w-3/5 lg:w-4/5 w-full aspect-video"
            src="https://www.youtube.com/embed/icsZPtNcdhI?rel=0&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen />



          <p className="italic text-center text-xl my-3">Now streaming on</p>
          <div className="flex justify-center items-center gap-x-5 mb-5">
            {
              STREAMING_LINKS.map((i, key) => {
                return (
                  <StreamingLink key={key} src={i.src} alt={i.alt} href={i.href} />
                )
              })
            }

          </div>

        </section>

        <div className="mt-5 h-[20px]"></div>
        <>
          {/* {
          PostcardData?.postcards ? (
            <div className="py-10">
              <div className="grid grid-cols-4 gap-8 sm:gap-14 xl:gap-20">
                {
                  PostcardData.postcards.map( (pc, index) =>  
                    <section key={"pc-" + index}>
                      {
                        <div className="col-span-2 lg:col-span-1">
                          <a target="_blank" rel="noreferrer" href={pc.path} className="d-block"><Image height={491} width={737} className="" src={pc.path} alt=""/></a>
                        </div>
                      }
                    </section>
                  )
                }
              </div>
            </div>
          ) : null
        } */}


          <iframe
            style={{ width: '100%', height: '1000px' }}
            className="w-full h-[1000px]"
            src="https://kyna.ph/towol/"
            // src="http://localhost:8888/ScatteredPolaroidsGallery-master/"
            frameBorder="0"
          />

        </>
      </BaseTemplate>

    </>
  );
}

const StreamingLink = ({ src, alt, href }) => {
  return (
    <Link href={href}>
      <Image className="cursor-pointer" src={src} alt={alt} width={44} height={44} />
    </Link>
  )
}
export default Home;
