import Navbar from "./Navbar.jsx";

const BaseTemplate = ({ children, bgClass, childClass }) => {
  return (
    <div>
      <Navbar />
      <main>
        <div className={`w-full min-h-screen lg:px-10 md:px-10 px-6 lg:pt-20 md:pt-36 pt-24 bg-no-repeat bg-top bg-cover font-EBGaramond text-white flex justify-center items-center ${bgClass}`}>
          <div className={`container w-full mx-auto ${childClass}`}>{children}</div>
        </div>
      </main>
    </div>
  );
};
export default BaseTemplate;
