import axios from 'axios';

const X_API_KEY = process.env.NEXT_PUBLIC_API_KEY;

export const KTGet = async (url, params) => {
  try {
    const res = await axios.get('/api/' + url, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': X_API_KEY,
      },
      params : params
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return {
        status: res.status,
        mesage: res.statusText,
      };
    }
  } catch (error) {
    console.log(error)
    return error;
  }
};

export const KTPost = async (url, data) => {
  try {
    const res = await axios.post('/api/' + url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': X_API_KEY,
      },
    });
    if (res.status === 200) {
      return res.data;
    } else {
      console.log(error)
      throw new Error('Error, please try again');
    }
  } catch (error) {
    throw new Error(error);
  }
};